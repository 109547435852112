import React, { Component } from 'react'

class NotFound extends Component {
  async componentDidMount() {
    if (typeof global.window !== 'undefined') {
      global.window.location = '/';
    }
  }
  render() {
    return(
      <>
      </>
    )
  }
}

export default NotFound